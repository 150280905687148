import { useStore } from "vuex";
import { useRouter } from "vue-router";

function useServicePackRoutes() {
  const store = useStore();
  const router = useRouter();

  const servicePackPatientRoute = () => {
    const query: any = {};
    Object.keys(store.state.servicePack.form)
      .map((cd_estabelecimento) => Number(cd_estabelecimento))
      .forEach((cd_estabelecimento) => {
        query[`et${cd_estabelecimento}`] = [
          ...store.state.servicePack.form[cd_estabelecimento].cd_procedimento.map((_id) => `pe${_id}`),
        ];
      });

    return { name: "service-pack-confirm", query, params: { noScroll: "true" } };
  };

  function goToServicePackPatient() {
    const routeTo = { ...servicePackPatientRoute() };
    router.push(routeTo);
  }

  return {
    goToServicePackPatient,
    servicePackPatientRoute,
  };
}

export { useServicePackRoutes };
